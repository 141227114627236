<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Swal from "sweetalert2";
    import { FormWizard, TabContent } from "vue-form-wizard";
    import "vue-form-wizard/dist/vue-form-wizard.min.css";
    import Auth from '@/services/Auth';
    import modalEditPhone from "@/components/modals/auth/modalEditPhone";
    import modalChangePassword from "@/components/modals/account/modalChangePassword";

    export default {
        components: { 
            FormWizard,
            TabContent,
            Layout, 
            PageHeader,
            modalEditPhone,
            modalChangePassword
        },
        page: {
            title: "Profile",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                message2FA: localStorage.getItem('message2FA'),
                title: "Profile",
                items: [
                    {
                        text: "Profile",
                        active: true,
                        href: "/profile",
                    },
                ],
                user_id: '',
                submitted: false,
                tryingToEdit: false,
                show2faWizard: false,
                switch2FA: false,
                switch2FA_DB: false,
                error: '',
                qrCodeImage: '',
                showErrorQRGenerate: false,
                qrCodeImageError: '',
                tryingToGenerateQRCode: false,
                qrCodeGenerateSuccess: false,
                showConfirmErrorAlert: false,
                showConfirmSuccessAlert: false,
                confirmErrorMsg: '',
                confirmSuccessMsg: '',
                secret_code: '',
                tryingToConfirm: false,
                beforeChange: {
                    type: Function
                },
                auth_app_2fa_card: false,
                showPassChange: true,
                show2FAMessage: false,
                showLoader: false,
                showUserInfoLoader: false,
                userData: [],
                adminPhoneEdit: false,
                authType: '',
                authTypeDB: '',
                changingAuthType:false,
                show2fa: false,
                showPhone: false,
                auth_otp_2fa_card: false,
                otp_secret_code: '',
                issecretCodeInvalid: false,
                requestBtn: false,
                waitingCall:false,
                show2faValidated:false,
                OTPMessage:''
            };
        },
        async created() {
            await this.get2faTypes()
            await this.getUser()
            await this.auth2faStatus()
        },
        mounted() {
            this.show2FAMessage = false
            if(this.message2FA == '2FA_REQUIRED'){
                this.show2FAMessage = true
            }
        },
        methods: {

            async get2faTypes(){
                try {
                    await Auth.get2faTypes().then((response) => {
                        const authTypes = response.data.data;
                        if(authTypes){
                            this.authTypes = authTypes

                            for (let i = 0; i < authTypes.length; i++) {
                                if (authTypes[i].type === "otp") {
                                    this.showPhone = true
                                }
                            }
                        }
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }).finally(() => {

                    })

                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }
            },

            async getUser(){
                try {
                    this.showUserInfoLoader = true
                    await Auth.getUsers('source=profile').then((response) => {
                        this.userData = response.data.users;
                        if(this.userData[0]){
                            this.phone_country_code = this.userData[0].phone_country_code
                            this.authType = this.userData[0].auth_2fa_type != null ? this.userData[0].auth_2fa_type : null
                            this.authTypeDB = this.userData[0].auth_2fa_type != null ? this.userData[0].auth_2fa_type : null
                        }
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Can not get user")
                    }).finally(() => {
                        this.showUserInfoLoader = false
                    })

                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg("Can not get user")
                }
            },

            callModalPhoneEdit(){
                this.$bvModal.show("phone_edit");
            },

            async removePhone(){
                Swal.fire({
                    title: "Remove this phone number?",
                    text: "This phone number will be removed from the user!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Remove!",
                }).then((result) => {
                    if (result.value) {
                        try {
                            Auth.removePhone({
                                csrf_token: this.csrf_token
                            }).then((response) => {
                                if(response.data.data == 'success'){
                                    this.getUser()
                                }
                            }).catch(error => {
                                this.error = error.response.data.error ? error.response.data.error : "";
                                this.failedmsg(this.error)
                            }).finally(() => {
                            })
                        } catch (error) {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }
                    }
                });
            },

            handleTabChanged(prevIndex, nextIndex){
                if(prevIndex == 1 && nextIndex == 2){
                    this.showConfirmErrorAlert = false
                    this.showConfirmSuccessAlert = false
                    //this.qrCodeGenerateSuccess = false
                    this.showErrorQRGenerate = false
                }
            },

            async auth2faStatus(){
                try {
                    Auth.auth2faStatus()
                    .then((response) => {
                        this.show2fa = true
                        const res = response.data.status ? response.data.status : false;
                       /* this.switch2faDisable = false*/
                        if(res=='not_validated' || res=='missing_2fa_record'){
                            if(this.authType == 'app'){
                                this.show2faWizard = true
                                this.auth_app_2fa_card = true
                                this.show2faValidated = false
                                this.generateQRCode()
                            }else if(this.authType == 'otp' && this.userData[0]){
                                this.authType = ''
                            }else if(this.authType == 'email'){
                                this.auth_otp_2fa_card = true
                            }
                        } else if(res=='validated') {
                            if(this.authType == 'app'){
                                this.show2faValidated = true
                                this.auth_app_2fa_card = true
                            }
                           /* this.auth_app_2fa_card = true
                            this.switch2FA = true
                            this.switch2FA_DB = this.switch2FA*/
                        }                       
                    })
                    .catch(() => {
                        this.show2fa = false
                    /*    this.auth_app_2fa_card = false
                        this.switch2FA = false
                        this.switch2FA_DB = this.switch2FA
                    this.switch2faDisable = true*/
                    })
                }catch(error){
                    this.show2fa = false
                    this.error = error.response.data.error ? error.response.data.error : "";
                /*    this.switch2FA = false
                    this.switch2FA_DB = this.switch2FA
                    this.switch2faDisable = true*/
                }
            },

            switch2FAChange(){

                if(this.authType == 'app'){

                    Swal.fire({
                        title: "Do you want to enable (2FA) with Google Authenticator App ?",
                        icon: "question",
                        showCancelButton: true,
                        confirmButtonColor: "#34c38f",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonText: "Enable",
                    }).then((result) => {
                        if (result.value) {
                            //this.switch2FA = originalSwitchState
                            this.changeTwoFactorAuth()
                            this.auth_otp_2fa_card = false
                            this.show2faWizard = true
                            this.auth_app_2fa_card = true
                            this.authTypeDB = this.authType

                            //Generate QR Code
                            this.generateQRCode()
                        }else{
                            this.show2faWizard = false
                            this.authType = this.authTypeDB
                        }
                    });

                }else if(this.authType == 'otp'){

                    if(this.userData[0] && !this.userData[0].verified){
                        this.warningmsg('Please Enter Verified Phone Number first')
                        this.authType = this.authTypeDB
                        return;
                    }

                    Swal.fire({
                        title: "Do you want to enable (2FA) with OTP ?",
                        icon: "question",
                        showCancelButton: true,
                        confirmButtonColor: "#34c38f",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonText: "Enable",
                    }).then((result) => {
                        if (result.value) {
                            //this.switch2FA = originalSwitchState
                            this.changingAuthType = true
                            this.changeTwoFactorAuth().then((result) => {
                                Auth.confirm2FAOTP({
                                    csrf_token: this.csrf_token,
                                    type: 'otp'
                                }).then((result) => {
                                    this.successmsg("Confirmation Success", 'Logout/Login again for the changes to take effect');
                                }).catch((error) => {
                                    this.failedmsg("Confirmation Failed", error.response.data.error);
                                }).finally(() => {
                                    this.changingAuthType = false
                                })
                            })
                            this.show2faWizard = false
                            this.auth_app_2fa_card = false
                            this.authTypeDB = this.authType
                        }else{
                            this.authType = this.authTypeDB
                        }
                    });

                }else if(this.authType == 'email'){
                    Swal.fire({
                        title: "Do you want to enable (2FA) with Email Address ?",
                        icon: "question",
                        showCancelButton: true,
                        confirmButtonColor: "#34c38f",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonText: "Enable",
                    }).then((result) => {
                        if (result.value) {
                            //this.switch2FA = originalSwitchState
                            //this.changingAuthType = true
                            this.auth_otp_2fa_card = true
                            this.changeTwoFactorAuth().then((result) => {
                                Auth.confirm2FAOTP({
                                    csrf_token: this.csrf_token,
                                    type: 'email'
                                }).then((result) => {

                                }).catch((error) => {
                                    this.failedmsg("Confirmation Failed", error.response.data.error);
                                }).finally(() => {
                                    this.changingAuthType = false
                                })
                                this.changingAuthType = false
                            })
                            this.show2faWizard = false
                            this.auth_app_2fa_card = false
                            //this.authTypeDB = this.authType
                        }else{
                            this.authType = this.authTypeDB
                        }
                    });
                }else{
                    Swal.fire({
                        title: "Do you want to disable 2FA ?",
                        icon: "question",
                        showCancelButton: true,
                        confirmButtonColor: "#34c38f",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonText: "Disable",
                    }).then((result) => {
                        if (result.value) {
                            //this.switch2FA = originalSwitchState
                            this.disableTwoFactorAuth()
                            this.show2faWizard = false
                            this.auth_app_2fa_card = false
                            this.auth_otp_2fa_card = false
                            this.authTypeDB = this.authType
                        }else{
                            this.authType = this.authTypeDB
                        }
                    });
                }
            },

            async changeTwoFactorAuth(){
               await Auth.editUser({
                    auth_2fa_type: this.authType,
                    id: this.userData[0].id,
                    username: this.userData[0].username,
                    role: this.userData[0].role_id,
                    csrf_token: this.csrf_token
                }).then(() => {
                    if(this.authType == 'app'){
                        Auth.disable2FAOTP()
                            .then(() => {
                            })
                            .catch((error) => {
                            })
                    }else if(this.authType == 'email'){
                        Auth.disable2FA()
                            .then(() => {
                            })
                            .catch((error) => {
                            })
                    }
                }).catch((error) => {

                })
            },

            disableTwoFactorAuth(){
                this.show2faWizard = false
                this.auth_app_2fa_card = false
                this.changeTwoFactorAuth()
                Auth.disable2FA()
                    .then(() => {
                        this.successmsg("Two-Factor Authentication (2FA) with Google Authenticator App", "Disable success !");
                    })
                    .catch((error) => {
                        //this.warningmsg("Fail!", error.response.data.error);
                    })
                Auth.disable2FAOTP()
                    .then(() => {
                        this.successmsg("Two-Factor Authentication (2FA) with OTP", "Disable success !");
                    })
                    .catch((error) => {
                        //this.warningmsg("Fail!", error.response.data.error);
                    })
            },

            setupAppAuth(){

                this.switch2FA = !this.switch2FA

                if(this.switch2FA){
                    Swal.fire({
                        title: "Do you want to enable (2FA) with Google Authenticator App ?",
                        icon: "question",
                        showCancelButton: true,
                        confirmButtonColor: "#34c38f",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonText: "Enable",
                    }).then((result) => {
                        if (result.value) {
                            //this.switch2FA = originalSwitchState
                        }else{
                            this.show2faWizard = false
                            this.switch2FA = false
                        }
                    });
                }else{

                    Swal.fire({
                        title: "Disable Two-Factor Authentication (2FA) with Google Authenticator App ?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#34c38f",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonText: "Disable",
                    }).then((result) => {
                        if (result.value) {
                            this.show2faWizard = false
                            this.auth_app_2fa_card = false

                            Auth.disable2FA()
                                .then(() => {
                                    this.switch2FA = false
                                    this.successmsg("Two-Factor Authentication (2FA) with Google Authenticator App", "Disable success !");
                                })
                                .catch((error) => {
                                    this.switch2FA = this.switch2FA_DB;
                                    this.warningmsg("Fail!", error.response.data.error);
                                })
                        }else{
                            this.switch2FA = true
                        }
                    })
                }
            },

            async generateQRCode(){
                this.tryingToGenerateQRCode = true
                this.showErrorQRGenerate = false
                this.qrCodeImageError = ''
                this.qrCodeGenerateSuccess = false
                this.showConfirmErrorAlert = false
                this.showConfirmSuccessAlert = false
                this.secret_code = ''

                Auth.generateQR()
                    .then((response) => {
                        this.qrCodeImage = response.data.qrcode    
                        this.tryingToGenerateQRCode = false 
                        this.qrCodeGenerateSuccess = true           
                    })
                    .catch((error) => {
                        this.tryingToGenerateQRCode = false       
                        this.showErrorQRGenerate = true
                        this.qrCodeImageError = error.response.data.error
                    })
            },

            async confirm2FA(){
                this.submitted = true;
                this.showConfirmErrorAlert = false
                this.showConfirmSuccessAlert = false
                this.tryingToConfirm = true
                //this.qrCodeGenerateSuccess = false
                this.showErrorQRGenerate = false

                Auth.confirm2FA({
                    secret_code: this.secret_code,
                    csrf_token: this.csrf_token
                })
                .then(() => {
                    this.showConfirmSuccessAlert = true
                    this.confirmSuccessMsg = 'Logout/Login for the change to take effect'
                    this.tryingToConfirm = false
                    this.secret_code = ''
                    this.show2faWizard = false
                    this.switch2FA = true
                    this.successmsg("Confirmation Success",  this.confirmSuccessMsg);
                    this.auth_app_2fa_card = false
                })
                .catch((error) => {
                    this.showConfirmErrorAlert = true
                    this.confirmErrorMsg = error.response.data.error
                    this.tryingToConfirm = false
                    this.secret_code = ''
                })
            },

            validate2FAOTP(){
                this.waitingCall = true
                if(!this.otp_secret_code){
                    this.issecretCodeInvalid = true
                    return
                }
                this.issecretCodeInvalid = false
                this.tryingToEdit = true

                Auth.validate2FAOTP({
                    csrf_token: this.csrf_token,
                    secret_code: this.otp_secret_code
                }).then((result) => {
                    if(result.data.validate){
                        this.successmsg("Validation Success", 'Logout/Login again for the changes to take effect');
                        this.otp_secret_code = ''
                        this.auth_otp_2fa_card = false
                    }
                }).catch((error) => {
                    this.failedmsg("Validation Failed", error.response.data.error);
                }).finally(() => {
                    this.tryingToEdit = false
                    this.waitingCall = false
                })
            },

            requestNewOTPCode(){
                Auth.confirm2FAOTP({
                    csrf_token: this.csrf_token,
                    type: 'email'
                }).then((result) => {
                    this.requestBtn = true
                    this.OTPMessage = 'OTP Code Successfully sent'
                }).catch((error) => {
                    this.OTPMessage = 'OTP Code can not be sent'
                }).finally(() => {

                })
            },

            resetApp2fa(){
                Swal.fire({
                    title: "Do you want to reset (2FA) Google Authenticator App ?",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Reset",
                }).then((result) => {
                    if (result.value) {
                        this.tryingToEdit = true
                        //Generate QR Code
                        Auth.disable2FA()
                            .then(() => {
                                this.generateQRCode()
                                this.show2faWizard = true
                                this.auth_app_2fa_card = true
                                this.show2faValidated = false
                            })
                            .catch((error) => {
                            })
                            .finally(() => {
                                this.tryingToEdit = false
                            })
                    }
                });
            },

            callModalChangePassword(){
                this.user_id = localStorage.getItem('user_id')
                this.$bvModal.show("change_password");
            }

        },
        middleware: "authentication",
    };
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <h5>Admin Information <b-button variant="primary" @click="callModalChangePassword" class="d-inline float-end">Change Password</b-button>
                                </h5>
                                <hr>
                                <div class="mt-3" v-if="!showUserInfoLoader">
                                    <p class="mb-2 text-strong">Username :</p>
                                    <p class="font-size-16 mb-2 justify-content-between" v-if="userData[0] && userData[0].username">
                                        {{userData[0].username}}
                                    </p>
                                    <p class="mb-2 text-strong" v-if="showPhone">Phone :</p>
                                    <p class="font-size-16 mb-2 justify-content-between" v-if="showPhone">
                                        <span class="mw-80" v-if="userData[0] && userData[0].phone_number">{{userData[0].phone_country_code}}{{userData[0].phone_number}} ({{userData[0].verified ? 'Verified' : 'Not Verified'}})</span>
                                        <a href="javascript:void(0);" title="Modify User phone"
                                           class="px-2 text-primary" @click="callModalPhoneEdit()" v-if="userData[0] && !userData[0].verified ">
                                            <i class="uil uil-pen font-size-18"></i>
                                        </a>
                                        <a href="javascript:void(0);" title="Remove Phone" class="px-2 text-danger" v-if="userData[0] && userData[0].phone_number"
                                           @click="removePhone">
                                            <i class="uil uil-trash font-size-18"></i>
                                        </a>
                                    </p>
                                    <div v-if="show2fa">
                                        <p class="mb-2 text-strong">Two Factor Authentication (2FA) : <b-spinner v-show="changingAuthType" small></b-spinner></p>
                                        <select v-model="authType" class="form-select col-2" @change="switch2FAChange()">
                                            <option
                                                    v-for="(type) in authTypes"
                                                    :key="type.type"
                                                    :value="`${type.type}`"
                                            >
                                                {{ type.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <template v-else>
                                    <div class="card-body d-flex align-items-center justify-content-center" >
                                        <b-spinner large></b-spinner>
                                    </div>
                                </template>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <b-alert variant="warning" :show="show2FAMessage" dismissible>
            Configure Two-factor authentication (2FA) before continue using Admin Panel !
        </b-alert>

        <div class="row" v-show="auth_otp_2fa_card">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h2 class="card-title mb-3 text-center">Setup Two Factor Authentication (2FA) with One Time Password sent to your Email</h2>

                        <div class="col-12">
                            <h5 class="mb-5">Enter the code sent to your email for confirmation:</h5>
                            <input type="text" class="form-control col-3 d-inline me-2" :class="{ 'is-invalid': issecretCodeInvalid }" v-model="otp_secret_code" placeholder="Enter OTP Code" autocomplete="off"/>
                            <b-button variant="primary" @click="validate2FAOTP" class="d-inline me-2" :disabled="tryingToEdit">Verify <b-spinner v-show="waitingCall" small></b-spinner></b-button>
                            <b-button variant="primary" @click="requestNewOTPCode" class="d-inline" :disabled="tryingToEdit || requestBtn">Request Code</b-button>
                        </div>
                        <div class="col-12" v-show="OTPMessage.length">
                            <div class="alert alert-info alert-dismissible fade show" role="alert">
                                {{OTPMessage}}
                                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-show="auth_app_2fa_card">
            <div class="col-12">
                <div class="card">
                    <div class="card-body" v-if='show2faValidated'>
                        <h2 class="card-title mb-3 text-center">Two Factor Authentication (2FA) with Google Authenticator is already set up</h2>
                        <p class="text-center">If you want to reset Google Authenticator click the button below</p>
                        <div class="d-grid gap-2 col-5 mx-auto">
                            <b-button variant="primary" @click="resetApp2fa" class="text-center" :disabled="tryingToEdit">Setup New 2FA <b-spinner v-show="waitingCall" small></b-spinner></b-button>

                        </div>
                    </div>
                    <div class="card-body" v-if="!show2faValidated">
                        <h2 class="card-title mb-3 text-center">Setup Two Factor Authentication (2FA) with Google Authenticator</h2>
                       <!-- <div class="form-check form-switch form-switch-lg mb-5" >
                            <label class="form-check-label" for="enable2FA">Enable 2FA</label>
                            <input type="checkbox" class="form-check-input" id="enable2FA" :checked="switch2FA" @click="switch2FAChange()" :disabled="switch2faDisable" />
                        </div>-->

                        <form-wizard color="#5b73e8" v-show="show2faWizard" @on-change="handleTabChanged">
                            <tab-content icon="mdi mdi-book-open-page-variant-outline">
                                <div class="row">
                                    <div class="col-12">
                                        <h5 class="mb-4">Protect your account with 2 Step Verification: </h5>

                                        <ol>
                                            <li class="mb-2">
                                                Install Google Authenticator app on your mobile device:
                                                <a class="ms-4 me-4" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US&pli=1" target="_blank">Android</a>
                                                <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">IOS</a>
                                            </li>
                                            <li class="mb-2">
                                                Scan the QR Code with Google Authenticator App
                                            </li>
                                            <li class="mb-2">
                                                Confirm App code generated from Google Authenticator App
                                            </li>
                                        </ol>
                                    </div>
                                    <!-- end col -->
                                </div>
                                <!-- end row -->
                            </tab-content>
                            <tab-content icon="mdi mdi-qrcode-scan" class="mb-5">
                                <div class="row">
                                    <div class="col-12">
                                        <h5  class="mb-5 d-inline me-3">Scan QR Code:</h5>
                                       <!-- <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light" @click="generateQRCode()">
                                            <i class="mdi mdi-qrcode-scan me-2"></i>
                                            Generate

                                        </a>-->
                                        <div>
                                            <h6 class="mb-5 mt-3">Scan QR Code with your Application and go to NEXT Step.</h6>
                                        </div>
                                        <b-spinner v-show="tryingToGenerateQRCode" small></b-spinner>
                                        <div v-show="qrCodeGenerateSuccess" v-html="qrCodeImage"></div>
                                        <b-alert class="mt-2 mb-5" variant="danger" v-model="showErrorQRGenerate">{{ qrCodeImageError }}</b-alert>
                                    </div>
                                    <!-- end col -->
                                </div>
                                <!-- end row -->
                            </tab-content>
                            <tab-content icon="mdi mdi-checkbox-marked-circle-outline">
                                <div class="row">
                                    <div class="col-12">
                                        <h5 class="mb-5">Enter Secret Code from your Application for confirmation:</h5>
                                    </div>
                                    <div class="mt-3 mb-5">
                                        <form class="row row-cols-lg-auto gx-3 gy-2 align-items-center" @submit.prevent="confirm2FA">
                                            <div class="col-12">
                                                <input type="text" class="form-control" id="input_secret_code" v-model="secret_code" placeholder="Enter Secret Code" autocomplete="off"/>
                                            </div>
                                            <div class="col-12">
                                                <button type="submit" class="btn btn-success" :disabled="tryingToConfirm">
                                                    <b-spinner v-show="tryingToConfirm" small></b-spinner>
                                                    Confirm
                                                </button>
                                            </div>
                                            <input type="hidden" name="csrf_token" v-model="csrf_token">
                                        </form>
                                    </div>
                                    <b-alert class="mt-2 mb-5" variant="danger" v-model="showConfirmErrorAlert" >{{ confirmErrorMsg }}</b-alert>
                                    <b-alert class="mt-2 mb-5" variant="success" v-model="showConfirmSuccessAlert">{{ confirmSuccessMsg }}</b-alert>
                                    <!-- end col -->
                                </div>
                                <!-- end row -->
                            </tab-content>
                        </form-wizard>
                    </div>
                    <!-- end card-body -->
                </div>
            </div>
        </div>

        <modalEditPhone :phoneData="userData" @onRefresh="getUser()"></modalEditPhone>
        <modalChangePassword></modalChangePassword>
    </Layout>
</template>