<script>
    import { required, minLength, numeric } from "vuelidate/lib/validators";
    import General from '@/services/General';
    import validationMessages from '@/components/validations'
    import Auth from '@/services/Auth';

    export default {
        components:{
            validationMessages
        },
        props: ['phoneData'],
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                phone_number: '',
                phone_country_code: '',
                showModal: false,
                countries: {},
                tryingToValidate: false,
                validate: false,
                verification_code: '',
                showLoader: false,
                tryingToConfirm: false,
                submitted:true,
            };
        },
        validations: {
            phone_number: {
                required,
                minLength: minLength(2),
                numeric
            },
        },

        methods: {

            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            async verifyPhone(){

                if(this.phone_number.trim() == ''){
                    this.failedmsg('Please Enter Valid Phone Number')
                    return;
                }

                this.tryingToValidate = true
                try {
                    await Auth.verifyPhone({
                        phone_number: this.phone_number,
                        phone_country_code: this.phone_country_code,
                        csrf_token: this.csrf_token
                    }).then((response) => {
                        /*if(response.data.data == 'success'){

                        }*/
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }).finally(() => {
                    })
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }finally{
                    this.tryingToValidate = false
                }
            },

            async confirmPhone(){

                if(this.verification_code.trim() == ''){
                    this.failedmsg('Please Enter Verification Code')
                    return;
                }

                this.$v.$touch()
                this.tryingToConfirm = true
                this.tryingToValidate = true
                this.submitted = true

                if (this.$v.$invalid) {
                    this.tryingToConfirm = false
                    return;
                } else {
                    try {
                        await Auth.confirmPhone({
                            verification_code: this.verification_code,
                            csrf_token: this.csrf_token
                        }).then((response) => {
                            if(response.data.data == 'success'){
                                this.successmsg('Phone successfully verified')
                                this.refreshData()
                                this.phone_number = ''
                                this.phone_country_code = ''
                                this.closeModal();
                            }
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }finally{
                        this.tryingToValidate = false
                        this.tryingToConfirm = false
                        this.submitted = false
                    }
                }
                this.tryingToConfirm = false
            },

            async getCountry() {
                try {
                    await General.getCountry({

                    }).then((response) => {
                        this.countries = this.preferredCountryOrder(response.data, 'name');
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Can not get countries")
                    }).finally(() => {
                    })

                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg("Can not get countries")
                }
            },

            closeModal() {
                this.refreshData()
                this.showModal = false;
                this.verification_code = '';
            },

            modalShown(){
                this.getCountry()

                if(this.phoneData[0]){
                    this.phone_number = this.phoneData[0].phone_number
                    this.phone_country_code = this.phoneData[0].phone_country_code
                }


            }
        }
    };
</script>

<template>
    <b-modal v-model="showModal" @shown="modalShown()" id="phone_edit" title="Edit Phone" title-class="font-18" size="lg" centered>

        <form action="" @submit.prevent="confirmPhone">
            <div class="row">
                <div class="col-12">
                    <label>Phone Number</label>
                    <b-input-group>
                        <div class="col-3">
                            <select v-model="phone_country_code" class="form-select">
                                <option v-for="country in countries" :key="country.alpha2code" :value="country.calling_code">{{country.alpha2code}} ({{country.calling_code}})</option>
                            </select>
                        </div>
                        <b-input-group-append class="col-5">
                            <b-form-group label-for="formrow-phone-input">

                                <b-form-input
                                        v-model.trim="phone_number"
                                        type="text"
                                        pattern= "[0-9]"
                                        id="phone"
                                        aria-describedby="order-feedback"
                                        :class="{
                                            'is-invalid': submitted && $v.phone_number.$error,
                                        }"
                                >
                                </b-form-input>
                                <validationMessages class="position-absolute" v-if="validate" :fieldName="'Phone'" :validationName="$v.phone_number"></validationMessages>
                            </b-form-group>
                        </b-input-group-append>

                        <b-input-group-append class="col-4">
                            <b-button variant="primary" @click="verifyPhone" :disabled="tryingToValidate || showLoader">
                                <b-spinner v-show="tryingToValidate" small></b-spinner> Get Verification Code
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </div>
                <div class="col-12">
                    <label>Confirm Verification Code</label>
                    <b-input-group>
                            <b-form-group label-for="verification_code-input" class="mb-3" >
                                <b-form-input
                                        v-model.trim="verification_code"
                                        type="text"
                                        aria-describedby="verification_code-input"
                                        aria-autocomplete="none"
                                >
                                </b-form-input>
                            </b-form-group>
                    </b-input-group>
                </div>
            </div>
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="confirmPhone" :disabled="tryingToValidate || showLoader">
                <b-spinner v-show="tryingToConfirm" small></b-spinner> Confirm & Save
            </b-button>
        </template>
    </b-modal>
</template>